body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  margin:auto;
  max-width:1380px;
  padding: 0 25px;
}
.d-flex{
  display:flex;
}

@media (max-width: 768px) {
  .container{
    padding: 0 15px;
  }
}

.logo {
  text-align: center;
  margin: 50px;
}

.logo img {
  width: 200px;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-color: #777;
}

.styled-table thead tr {
  background-color: #005880;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #005880;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #005880;
}

.styled-table tfoot {
  font-weight: bold;
}

.styled-table tfoot td:first {
  text-align: left;
}

.add-json-data-btn {
  display: none !important;
}

.custom-btn {
  border: 1px solid #005880;
  background-color: #005880;
  color: white;
  padding: 10px;
  border-radius: 8px;
  text-decoration: none;
  display: inline-block;
}

.enrollment-footer {
  margin-bottom: 60px;
}

.spinner{
  margin: 45vh auto;
}

.mid-spinner{
  margin: 20vh auto;
}


@media print {
  button {
     display: none;
  }
  }