
.banner-small {
  padding: 0;
  background-color: #ebebeb;
  color: #231f20;
  font-weight: 500;
  height: 8rem;
  margin-bottom: 5rem;
  position: relative;
}
.banner-title-card {
  background-color: white;
  padding: 2.75rem 3rem 1.75rem 1.5rem;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid #a3a3a3;
  position: absolute;
  top: 3rem;
  box-shadow: 0 0 16px #c9c9c929;
}
.banner-title {
  font-weight: 700;
  font-size: 2rem;
  color: #000;
  margin: 0;
}
.banner-title:before {
  content: "";
  width: 2rem;
  height: 0.35rem;
  background-color: #005880;
  display: block;
  top: -1rem;
  left: 0;
  position: relative;
}

.courses-heading {
  text-align: center;
}

.qualification-heading {
  margin: 0;
  text-align: left;
}

.user-detail {
  display: flex;
  justify-content: end;
    align-items: center;
    column-gap: 1rem;
    padding: 0px;
    border-bottom: solid 1px #ddd;
}

.courses-page-container {
  text-align: center;
  padding-top: 15px;
}

.courses-grid {
  display: flex;
  flex-direction: row;
    align-items: center;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    margin-bottom: 2.5rem;
    flex-wrap: wrap;
    row-gap: 1rem;
}

.course-card {
  background: #fff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  box-shadow: 2px 3px 5px hsla(0,0%,77%,.25);
  min-width: 180px;
  padding: 20px 50px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-decoration: none;
    font-size: 18px;
    color: black;
    font-weight: bold;
}

@media (max-width: 768px) {
  .course-body {
    margin: 20px 15px;
  }
  .banner-title {
    font-size: 1.35rem;
  }
  .banner-title-card {
    padding: 2.25rem 1.75rem 1.25rem 1.25rem;
    top: 3.5rem;
  }

  .qualification-heading {
    margin: 10px;
    text-align: left;
  }
}