.enrollment-body {
    margin: 0px;
    text-align:center;
}

.enrollment-table{
    margin: 0 auto;
}
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

/* .batch-heading {
    margin: 20px; 
    text-align: center;
  } */