.registration-heading {
    margin: 50px;
    text-align: center;

}

.registration-body {
    margin: 50px;
}

.registration-form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: left;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .form-row {
    margin-bottom: 20px;
  }

  .form-rowinline {
    display: flex;
    margin-bottom: 20px;
  }
  
  .form-group {
    flex: 1;
    margin-right: 10px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .form-row {
      flex-direction: column;
    }
  
    .form-group {
      margin-right: 0;
    }
  
    button {
      width: 100%;
    }

    input {
        width: 90%;
      }
  }

  .custom-select {
    /* appearance: none; */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    margin: 0;
  }
  
  .custom-select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  