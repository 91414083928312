.batch-body {
  margin: 0px;
}



.batch-heading {
  margin: 20px; 
  text-align: center;
}

.batch-heading-btn { 
  display: flex;
}

.batch-qualification-btn {
  display: flex;
}

.batch-back-btn {
  margin: 15px;
}

.batch-page-container {
  text-align: center;
  padding: 20px;
}

.cart-container {
  width:30%;
}
.cart-inner{
  background: #fff;
  border: 2px solid #ebebeb;
  box-sizing: border-box;
  box-shadow: 0 3px 15px hsla(0,0%,77%,.25);
  border-radius: 12px;
  padding: 1.75rem;
}
.cart-inner h2{
  margin-top:0;
}
.cart-inner .top-label{
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #c4c4c4;
  margin: 0;
}
.cart-inner .total-amount {
  font-weight: 700;
  font-size: 2.05rem;
  color: #000;
  margin: 0 0 1.5rem;
}
.cart-inner .selected-batches-text{
  font-size: 1.0125rem;
    line-height: 1.5rem;
    text-transform: uppercase;
}


.selected-batches{
  padding: 0;
  list-style-type: none;
}
.batch-list-container{
  width:70%;
}

.selected-batch-card {
  display: flex;
  justify-content: space-between;
}

.selected-batch-container {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: column;
    margin-bottom:10px;
}
.selected-batch-container h3,
.selected-batch-container h4,
.selected-batch-container p{
  margin-top:0;
}
.selected-batch-container h4,
.selected-batch-container p{
  margin-bottom:5px;
}
.selected-batch-container p{
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 2px;
}
.selected-batches-wrapper button{
  width:100%;
}

.subject-grid {
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));/ */
  /* grid-gap: 20px; */
  /* align-items: center; */
  /* justify-items: center; */
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  row-gap: 1rem;
  flex-wrap: wrap;
}

.subject-name {
  margin: 0px;
  width:100%;
}

.subject-card {
  /* background-color: #f2f2f2;
  border: 2px solid black;
  width: 300px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 20px; */
  background: #fff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    box-shadow: 0 3px 6px hsla(0,0%,77%,.25);
    border-radius: 12px;
    padding: 1.5rem 1.5rem 1.5rem 2rem;
    width: calc(50% - 0.5rem);
    display: flex;
    flex-direction: column;
    height: -moz-fit-content;
    height: fit-content;
}
.subject-card h4 {
  margin: 0;
  font-size: 20px;
  border-bottom: solid 1px #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.batch-card {
  padding: 10px 20px;
  margin-left: 10px;
  flex-grow: 1;
}
.batch-card-disable {
  padding: 10px 20px;
  margin-left: 10px;
  color: gray;
}

.batch-card h5,
.batch-card-disable h5{
  font-size:15px;
  margin: 0 0 5px 0;
}
.batch-card p,
.batch-card-disable p{
  margin:0 0 2px 0;
  line-height: 1.3;
  font-size: 14px;
}

.batch-container {
  display: flex;
  margin-bottom: 15px;
  padding: 0 10px;
}

.trainer-name {
  font-weight: bold;
  margin: 8px 0 !important;
}

.batch-desc p {
  font-size: 14px !important;
  color: #666;
}

.batch-seats-label {
  font-size: 12px !important;
  color: #777;
  font-style: italic;
}

.batch-price-text {
  font-weight: bold;
  font-size: 16px !important;
  margin: 6px 0 !important;
}

.batch-checkbox {
  margin: auto 0;
  cursor: pointer;
}
.batch-checkbox .batch-checkbox_input{
  opacity: 0;
  position: absolute;
}
.batch-checkbox_control {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 12px;
  vertical-align: middle;
  background-color: inherit;
  color: #007bff;
  border: 2px solid #ccc;
  background-color: #fafafa;
  border-radius: 24px;
}
.batch-checkbox_input:checked+.batch-checkbox_control:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 16px;
  height: 16px;
  background-color: #007bff;
  border-radius: 12px;
}

.batch-checkbox_input:checked+.batch-checkbox_control {
  border-color: #007bff;
  background-color: #fff;
}
.batch-checkbox_input:checked+.batch-checkbox_control {
  border-color: #007bff;
  background-color: #fff;
}
.batch-checkbox_input[disabled]+.batch-checkbox_control {
  border-color: #ccc !important;
}
.batch-checkbox_input[disabled]+.batch-checkbox_control:after {
  background-color: #ccc !important;
}
.batch-checkbox_control {
transform: scale(0.75);
}



.batch-add-btn {
  text-align: right;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.course-reg-container{
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
  column-gap: 2rem;
}


@media (max-width: 768px) {

  .course-body {
    margin: 20px;
  }
  .batch-heading {
    font-size: 26px;
  }
  .course-reg-container {
    padding-top: 1rem;
  }

  .qualification-heading {
    margin: 10px;
  }



  
  .batch-heading-btn {
    display: block;
  }

  .selected-batch-card {
    display: block;
    justify-content: space-between;
  }
   .course-reg-container{
    flex-direction: column-reverse !important;
  }
  .cart-container,
  .batch-list-container {
    width: 100%;
  }
  .batch-list-container {
    padding-bottom: 25px;
  }
  .batch-back-btn button{
    width:auto;
  }
  .batch-back-btn{
    margin-left:0;
  }
  .batch-card,
  .batch-card-disable {
    margin-left: 0;
  }
  .cart-inner .total-amount {
    font-size: 1.75rem;
    margin: 0 0 1.25rem;
  }
  .cart-inner{
    margin-bottom: 40px;
    padding: 1.25rem;
  }

}

@media (max-width: 640px) {
  .subject-card {
    width: calc(100% - 0.5rem);
    padding: 1.5rem 1.2rem 1.5rem 1.2rem;
  }
}